import React, { useState, useEffect } from 'react';

import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from 'primereact/inputtextarea';

import { Dropdown } from 'primereact/dropdown';
import { LANGUAGE, YES_NO } from '../../../utilities/constants/ITMISConstansts';
import { t } from 'i18next';
import { MultiSelect } from 'primereact/multiselect';
import i18n from '../../../il8n/il8n';
import AddressService from '../../../security/api/services/AddressService';
import { useForm, Controller } from "react-hook-form";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";

const TipDetail2 = (props) => {
    const { control, } = useForm({ mode: "all", });
    const [type, setType] = useState();
    const [typeOfTipCaseInternal, setTypeOfTipCaseInternal] = useState();
    const [typeOfTipCaseExternal, setTypeOfTipCaseExternal] = useState();
    const [provincesListSh, setProvincesListSh] = useState([]);
    const [districtsListSh, setDistrictListSh] = useState([]);
    const [municipalitiesListSh, setMunicipalitiesListSh] = useState([]);
    const [wardListSh, setWardListSh] = useState([]);
    const [districtsAllList, setDistrictsAllList] = useState([]);
    const [countriesList, setCountriesList] = useState([]);
    const [flag, setFlag] = useState();
    const [val, setVal] = useState();

    const pushFactorsList = [
        { label: t("landless"), value: "Landless" },
        { label: t("debt"), value: "Debt" },
        { label: t("familyPeerPressure"), value: "Family or peer pressure" },
        { label: t("houseLandPurchase"), value: "House or land purchase" },
        { label: t("noIncomeSources"), value: "No income sources" },
        { label: t("noEmploymentOpportunity"), value: "No employment opportunity" },
        { label: t("displacement"), value: "Displacement" },
        { label: t("domesticGenderBasedViolence"), value: "Domestic or gender based violence" },
        { label: t("divorce"), value: "Divorce" },
        { label: t("childMarriage"), value: "Child marriage" },
        { label: t("forcedMarriage"), value: "Forced marriage" },
        { label: t("rape"), value: "Rape" },
        { label: t("sexualViolence"), value: "Sexual violence" },
        { label: t("orphanAbandoned"), value: "Orphan or abandoned" },
        { label: t("socialPressure"), value: "Social pressure" },
        { label: t("peerPressure"), value: "Peer pressure" },
        { label: t("politicalInstability"), value: "Political Instability" },
        { label: t("othersPleaseSpecify"), value: "Others" },
    ];

    const pullFactorsList = [
        { label: t("betterEmployment"), value: "Better employment" },
        { label: t("higherWages"), value: "Higher wages" },
        { label: t("additionalFacilities"), value: "Additional facilities" },
        { label: t("personalInterest"), value: "Personal interest" },
        { label: t("modernLifestyle"), value: "Modern lifestyle" },
        { label: t("economicIndependency"), value: "Economic independency" },
        { label: t("exaggeratedInfoAboutPlace"), value: "Exaggerated info about place" },
        { label: t("othersPleaseSpecify"), value: "Others" },
    ];

    const directServicesList = [
        { label: t("shelterAccommodation"), value: "Shelter or Accommodation" },
        { label: t("food"), value: "Food" },
        { label: t("clothes"), value: "Clothes" },
        { label: t("psychosocialCounseling"), value: "Psychosocial counseling" },
        { label: t("skillOrCapacityDevelopmentTraining"), value: "Skill or capacity development training" },
        { label: t("formalEducation"), value: "Formal education" },
        { label: t("informalEducationMotivationalSession"), value: "Informal education or motivational session" },
        { label: t("healthAndMedicalService"), value: "Health and medical service" },
        { label: t("jobPlacement"), value: "Job placement" },
        { label: t("businessSupport"), value: "Business support" },
        { label: t("legalServices"), value: "Legal services" },
        { label: t("othersPleaseSpecify"), value: "Others" },
    ];

    //new
    const [referralList, setValueReferralList] = useState([
        { labelEng: " Health and medical service", labelNep: " स्वास्थ्य र चिकित्सा", value: "Health service" },
        { labelEng: " Skill or capacity development training", labelNep: " सीप तथा क्षमता विकास तालिम", value: "Skill training" },
        { labelEng: " Job placement", labelNep: " जागिर नियुक्ति", value: "Job placement" },
        { labelEng: " Business support", labelNep: " पेसा व्यवसाय सहायता", value: "Business support" },
        { labelEng: " Psychosocial counseling", labelNep: " मनोसामाजिक परामर्श", value: "Psychosocial counseling" },
        { labelEng: " Legal and paralegal services", labelNep: " कानूनी र पारालीगल सेवाहरू", value: "Legal services" },
        { labelEng: " Formal education", labelNep: " औपचारिक शिक्षा", value: "Formal education" },
        { labelEng: " Informal education/motivational session", labelNep: " अनौपचारिक शिक्षा/प्रेरणा सत्र", value: "Informal education" },
        { labelEng: " Others (Please specify)", labelNep: " अन्य (कृपया उल्लेख गर्नुहोस्)", value: "Others" },
        { labelEng: " Not referred yet", labelNep: " सेवाका लागि रेफर गरिएको छैन", value: "Not referred yet" },
    ]);

    useEffect(() => {
        console.log("props>?>>>>>>",props.getValues("tipDetail.provinceSh"));
       
        AddressService.getAllProvinces().then((response) => {
            setProvincesListSh(response.data.data);
        });
    }, []);

    useEffect(() => {
        if (props.getValues("tipDetail.provinceSh")) {
            getDistrictValueSh(props.getValues("tipDetail.provinceSh"));
            getMunicipalitiesListSh(props.getValues("tipDetail.districtSh"));
            getTotalWardSh(props.getValues("tipDetail.municipalitySh"));
            props.setValue("tipDetail.provinceSh", parseInt(props.getValues("tipDetail.provinceSh")));
            props.setValue("tipDetail.districtSh",parseInt(props.getValues("tipDetail.districtSh")));
            props.setValue("tipDetail.municipalitySh", parseInt(props.getValues("tipDetail.municipalitySh")));
            props.setValue("tipDetail.wardNoSh",parseInt(props.getValues("tipDetail.wardNoSh")));
        }
    }, [props]);

    const getDistrictValueSh = (provinceId) => {
        AddressService.getAllDistrictsByProvinceId(provinceId).then((response) => {
            setDistrictListSh(response.data.data);
        });
    };

    const getMunicipalitiesListSh = (districtId) => {
        if (districtId !== undefined && districtId !== NaN) {
            AddressService.getAllMunicipalitiessByDistrictId(districtId).then((response) => {
                setMunicipalitiesListSh(response.data.data);
            });
        }
    };

    const getTotalWardSh = (municipalityId) => {
        AddressService.getTotalWard(municipalityId).then((response) => {
            const totalWard = response.data.data;
            const list = [];
            for (var i = 1; i <= response.data.data; i++) {
                list.push({
                    wardId: i,
                });
            }
            setWardListSh(list);
        });
    };
    const [addressValue, setAddressValue] = useState();
    const [value, setValue] = useState('');

    const [tip, setValueTip] = useState('');
    const setTypeOfTipCase = (val) => {
        console.log("hello");
        setValueTip(val)
    };

    let referals = (props.getValues("referral") || []).join(',');

    useEffect(() => {
        if (flag) return;
        const refData = props.getValues("referral") || [];

        if (refData.length > 0) {
            let newList = referralList.map(a => {
                if (refData.find(val => val === a.value)) {
                    if (!a.checked) {
                        a.checked = !a.checked;
                    }
                }
                return a;
            });
            setFlag(true);
            setValueReferralList(newList);
            setReferralVal(refData);
        }
    }, [referals]);

    const [referralVal, setReferralVal] = useState([]);

    const checkboxReferralListFunction = (val) => {
        console.log("referralList", referralList);

        console.log("val", val);
        let list = [];
        let newList = referralList.map(a => {
            if (a.value === val) {
                a.checked = !a.checked;
            }
            return a;
        });
        let newReferralList = newList.map(data => {
            if (data.checked && data.checked === true) {
                console.log("list", list);
                list.push(data.value);
            }
            return list;
        });

        setValueReferralList(newList);
        setReferralVal(newReferralList[0]);
        props.setValue("referral", newReferralList[0]);
    }

    const reintegrationStatusList = [
        { label: t("Family"), value: "Family" },
        { label: t("relatives"), value: "Relatives" },
        { label: t("livingAlone"), value: "Living alone" },
        { label: t("Community"), value: "Community" },
        { label: t("outsideOfCommunity"), value: "Outside of community" }
    ];

    const [pushFactorsVal, setPushFactorsVal] = useState("No");
    const [pullFactorsVal, setPullFactorsVal] = useState("No");
    const [directServicesVal, setDirectServicesVal] = useState("No");


    const handlePushFactors = (value) => {
        (value.includes("Others") ?
            setPushFactorsVal("Yes") :
            setPushFactorsVal("No")
        )
    }

    const handlePullFactors = (value) => {
        (value.includes("Others") ?
            setPullFactorsVal("Yes") :
            setPullFactorsVal("No")
        )
    }

    const handleDirectServices = (value) => {
        (value.includes("Others") ?
            setDirectServicesVal("Yes") :
            setDirectServicesVal("No")
        )
    }

    return (
        <>
            <div className="p-field p-col-12 p-md-12 ">
                <h3 style={{ textAlign: "center" }}>{t("riskOfVulnerability")}</h3>
            </div>

            <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-col-12 p-md-6">
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("pushFactors")}
                    </div>
                    <div className="p-field p-col-12 p-md-12 float-left">
                        <MultiSelect
                            name="pushFactors"
                            placeholder={t("select")}
                            {...props.register("tipDetail.pushFactors")}
                            value={props.getValues("tipDetail.pushFactors")}
                            options={pushFactorsList}
                            onChange={(e) => {
                                props.setValue("tipDetail.pushFactors", e.value);
                                handlePushFactors(e.value)
                            }}
                        />
                    </div>
                </div>
                {props.getValues("tipDetail.pushFactors")?.includes("Others") ?
                    <div className="p-col-12 p-md-6">
                        <div className="p-field p-col-12 p-md-12 float-left main-label">
                            {t("pleaseSpDetails")}
                        </div>
                        <div className="p-field p-col-12 p-md-12 float-left">
                            <InputText
                                name="pushFactorsOthers"
                                {...props.register("tipDetail.pushFactorsOthers")}
                                value={props.getValues("tipDetail.pushFactorsOthers")}
                                onChange={(e) => {
                                    props.setValue("tipDetail.pushFactorsOthers", e.target.value);
                                    setVal(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    :
                    <></>
                }
            </div>

            <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-col-12 p-md-6">
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("pullFactors")}
                    </div>
                    <div className="p-field p-col-12 p-md-12 float-left">
                        <MultiSelect
                            name="pullFactors"
                            placeholder={t("select")}
                            {...props.register("tipDetail.pullFactors")}
                            value={props.getValues("tipDetail.pullFactors")}
                            options={pullFactorsList}
                            onChange={(e) => {
                                props.setValue("tipDetail.pullFactors", e.value);
                                handlePullFactors(e.value)
                            }}
                        />
                    </div>
                </div>
                {props.getValues("tipDetail.pullFactors")?.includes("Others") ?
                    <div className="p-col-12 p-md-6">
                        <div className="p-field p-col-12 p-md-12 float-left main-label">
                            {t("pleaseSpDetails")}
                        </div>
                        <div className="p-field p-col-12 p-md-12 float-left">
                            <InputText
                                name="pullFactorsOthers"
                                {...props.register("tipDetail.pullFactorsOthers")}
                                value={props.getValues("tipDetail.pullFactorsOthers")}
                                onChange={(e) => {
                                    props.setValue("tipDetail.pullFactorsOthers", e.target.value);
                                    setVal(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    :
                    <></>
                }
            </div>

            <div className="p-field p-col-12 p-md-12 ">
                <h3 style={{ textAlign: "center" }}>{t("statusOfTipCase")}</h3>
            </div>

            <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-col-12 p-md-12 ">
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("tipCaseRegistrationStatus")}
                    </div>
                    <div className="p-field p-col-12 p-md-4 float-left">
                        <RadioButton
                            value="Case Registered"
                            name="Case Registered"
                            onChange={(e) => {
                                props.setValue("tipDetail.statusOfTip", "Case Registered");
                                setTypeOfTipCase(e.value);
                            }}
                            checked={props.getValues("tipDetail.statusOfTip") === "Case Registered"}
                        /> {t("caseRegistered")}
                    </div>
                    <div className="p-field p-col-12 p-md-6 float-left">
                        <RadioButton
                            value="Case Not Registered"
                            name="Case Not Registered"
                            onChange={(e) => {
                                props.setValue("tipDetail.statusOfTip", "Case Not Registered");
                                setTypeOfTipCase(e.value);
                            }}
                            checked={props.getValues("tipDetail.statusOfTip") === "Case Not Registered"}
                        /> {t("caseNotRegistered")}
                    </div>

                    {props.getValues("tipDetail.statusOfTip") === "Case Registered"
                        ? <div className="p-grid p-col-12 p-md-12 ">
                            <div className="p-col-12 p-md-12">
                                <div className="p-field p-col-12 p-md-12 float-left main-label">
                                    {t("caseRegistered")}
                                </div>

                                <div className="p-field p-col-12 p-md-4 float-left">
                                    <Checkbox
                                        value="FIRTIP Case Registration"
                                        name="FIRTIP Case Registration"
                                        onChange={(e) => {
                                            let selectedFormsCaseOfTip = props.getValues("tipDetail.caseRegistered") || [];
                                            if (e.checked) {
                                                selectedFormsCaseOfTip.push(e.value);
                                            } else {
                                                selectedFormsCaseOfTip = selectedFormsCaseOfTip.filter(form => form !== e.value);
                                            }
                                            props.setValue("tipDetail.caseRegistered", selectedFormsCaseOfTip);
                                        }}
                                        checked={props.getValues("tipDetail.caseRegistered")?.includes("FIRTIP Case Registration")}
                                    /> {t("firTipCaseRegistration")}
                                </div>
                                <div className="p-field p-col-12 p-md-4 float-left">
                                    <Checkbox
                                        value="Prosecution"
                                        name="Prosecution"
                                        onChange={(e) => {
                                            let selectedFormsCaseOfTip = props.getValues("tipDetail.caseRegistered") || [];
                                            if (e.checked) {
                                                selectedFormsCaseOfTip.push(e.value);
                                            } else {
                                                selectedFormsCaseOfTip = selectedFormsCaseOfTip.filter(form => form !== e.value);
                                            }
                                            props.setValue("tipDetail.caseRegistered", selectedFormsCaseOfTip);
                                        }}
                                        checked={props.getValues("tipDetail.caseRegistered")?.includes("Prosecution")}
                                    /> {t("prosecution")}
                                </div>
                                <div className="p-field p-col-12 p-md-4 float-left">
                                    <Checkbox
                                        value="Case Decided"
                                        name="Case Decided"
                                        onChange={(e) => {
                                            let selectedFormsCaseOfTip = props.getValues("tipDetail.caseRegistered") || [];
                                            if (e.checked) {
                                                selectedFormsCaseOfTip.push(e.value);
                                            } else {
                                                selectedFormsCaseOfTip = selectedFormsCaseOfTip.filter(form => form !== e.value);
                                            }
                                            props.setValue("tipDetail.caseRegistered", selectedFormsCaseOfTip);
                                        }}
                                        checked={props.getValues("tipDetail.caseRegistered")?.includes("Case Decided")}
                                    /> {t("caseDecided")}
                                </div>
                            </div>
                            {/* If selected prosecution/adjudication  */}
                            {props.getValues("tipDetail.caseRegistered")?.includes("Prosecution")
                                ? <div className="p-grid p-col-12 p-md-12 ">
                                    <div className="p-col-12 p-md-12">
                                        <div className="p-field p-col-12 p-md-12 float-left main-label">
                                            {t("prosecutionAt")}
                                        </div>
                                        <div className="p-field p-col-12 p-md-4 float-left">
                                            <RadioButton
                                                value="DistrictCourt"
                                                name="DistrictCourt"
                                                onChange={(e) => {
                                                    props.setValue("tipDetail.prosecutionAt", "DistrictCourt");
                                                    setTypeOfTipCase(e.value);
                                                }}
                                                checked={props.getValues("tipDetail.prosecutionAt") === "DistrictCourt"}
                                            /> {t("districtCourt")}
                                        </div>
                                        <div className="p-field p-col-12 p-md-4 float-left">
                                            <RadioButton
                                                value="HighCourt"
                                                name="HighCourt"
                                                onChange={(e) => {
                                                    props.setValue("tipDetail.prosecutionAt", "HighCourt");
                                                    setTypeOfTipCase(e.value);
                                                }}
                                                checked={props.getValues("tipDetail.prosecutionAt") === "HighCourt"}
                                            /> {t("highCourt")}
                                        </div>
                                        <div className="p-field p-col-12 p-md-4 float-left">
                                            <RadioButton
                                                value="SupremeCourt"
                                                name="SupremeCourt"
                                                onChange={(e) => {
                                                    props.setValue("tipDetail.prosecutionAt", "SupremeCourt");
                                                    setTypeOfTipCase(e.value);
                                                }}
                                                checked={props.getValues("tipDetail.prosecutionAt") === "SupremeCourt"}
                                            /> {t("supremeCourt")}
                                        </div>
                                    </div>
                                </div>
                                : <></>
                            }
                            {/* If selected Case Decided  */}
                            {props.getValues("tipDetail.caseRegistered")?.includes("Case Decided")
                                ? <div className="p-grid p-col-12 p-md-12 ">
                                    <div className="p-col-12 p-md-12">
                                        <div className="p-field p-col-12 p-md-12 float-left main-label">
                                            {t("caseDecidedBy")}
                                        </div>
                                        <div className="p-field p-col-12 p-md-4 float-left">
                                            <RadioButton
                                                value="DistrictCourt"
                                                name="DistrictCourt"
                                                onChange={(e) => {
                                                    props.setValue("tipDetail.caseDecidedBy", "DistrictCourt");
                                                    setTypeOfTipCase(e.value);
                                                }}
                                                checked={props.getValues("tipDetail.caseDecidedBy") === "DistrictCourt"}
                                            /> {t("districtCourt")}
                                        </div>
                                        <div className="p-field p-col-12 p-md-4 float-left">
                                            <RadioButton
                                                value="HighCourt"
                                                name="HighCourt"
                                                onChange={(e) => {
                                                    props.setValue("tipDetail.caseDecidedBy", "HighCourt");
                                                    setTypeOfTipCase(e.value);
                                                }}
                                                checked={props.getValues("tipDetail.caseDecidedBy") === "HighCourt"}
                                            /> {t("highCourt")}
                                        </div>
                                        <div className="p-field p-col-12 p-md-4 float-left">
                                            <RadioButton
                                                value="SupremeCourt"
                                                name="SupremeCourt"
                                                onChange={(e) => {
                                                    props.setValue("tipDetail.caseDecidedBy", "SupremeCourt");
                                                    setTypeOfTipCase(e.value);
                                                }}
                                                checked={props.getValues("tipDetail.caseDecidedBy") === "SupremeCourt"}
                                            /> {t("supremeCourt")}
                                        </div>
                                    </div>
                                </div>
                                : <></>
                            }
                        </div> : <></>}
                </div>
            </div>

            <div className="p-field p-col-12 p-md-12 ">
                <h3 style={{ textAlign: "center" }}>{t("servicesRegistered")}</h3>
            </div>
            {/* <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-col-12 p-md-12 ">
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("servicesRegistered")}
                    </div>
                    <div className="p-field p-col-12 p-md-4 float-left">
                        <RadioButton
                            value={YES_NO.YES}
                            name={YES_NO.YES}
                            onChange={(e) => {
                                props.setValue("tipDetail.servicesRegistered", YES_NO.YES);
                                setTypeOfTipCase(e.value);
                            }}
                            checked={props.getValues("tipDetail.servicesRegistered") === YES_NO.YES}
                        /> {t("yes")}
                    </div>
                    <div className="p-field p-col-12 p-md-4 float-left">
                        <RadioButton
                            value={YES_NO.NO}
                            name={YES_NO.NO}
                            onChange={(e) => {
                                props.setValue("tipDetail.servicesRegistered", YES_NO.NO);
                                setTypeOfTipCase(e.value);
                            }}
                            checked={props.getValues("tipDetail.servicesRegistered") === YES_NO.NO} /> {t("no")}
                    </div>
                </div>
            </div> */}
            {/* {props.getValues("tipDetail.servicesRegistered") === 'Yes'
                ? <> */}

            <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-col-12 p-md-6">
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("directServices")}
                    </div>
                    <div className="p-field p-col-12 p-md-12 float-left">
                        <MultiSelect
                            name="directServices"
                            placeholder={t("select")}
                            {...props.register("tipDetail.directServices")}
                            value={props.getValues("tipDetail.directServices")}
                            filter
                            options={directServicesList}
                            onChange={(e) => {
                                props.setValue("tipDetail.directServices", e.value);
                                handleDirectServices(e.value);
                            }}
                        />
                    </div>
                </div>
                {props.getValues("tipDetail.directServices")?.includes("Others") ?
                    <div className="p-col-12 p-md-6">
                        <div className="p-field p-col-12 p-md-12 float-left main-label">
                            {t("pleaseSpDetails")}
                        </div>
                        <div className="p-field p-col-12 p-md-12 float-left">
                            <InputText
                                name="directServicesOthers"
                                {...props.register("tipDetail.directServicesOthers")}
                                value={props.getValues("tipDetail.directServicesOthers")}
                                onChange={(e) => {
                                    props.setValue("tipDetail.directServicesOthers", e.target.value);
                                    setVal(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    :
                    <></>
                }
            </div>

            <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-col-12 p-md-6" style={{ width: "100%" }}>
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("referral")}
                    </div>
                    <div className="p-field p-col-12 p-md-12 float-left" style={{ marginTop: "10px" }}>
                        <div className="p-col-12 p-md-6 float-left">
                            {referralList.map((referral) => {
                                return (
                                    <div key={referral.key} className="p-col-12 p-md-12 flex align-items-center">
                                        <Checkbox
                                            name="referral"
                                            {...props.register("referral")}
                                            value={props.getValues("referral")}
                                            // checked={value === referral}
                                            onChange={(e) => {
                                                checkboxReferralListFunction(referral.value)
                                                console.log(referral);

                                            }}
                                            checked={referral.checked}
                                        />
                                        <label>{i18n.language == LANGUAGE.ENGLISH ? referral.labelEng : referral.labelNep}</label>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="p-col-12 p-md-6 float-right">
                            {referralVal?.includes("Health service") ?
                                <InputText
                                    name="healthOrganization"
                                    value={props.getValues("healthOrganization")}
                                    {...props.register("healthOrganization")}
                                    onChange={(e) => {
                                        props.setValue("healthOrganization", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }}
                                    placeholder={t("organizationName")}
                                    tooltip={t("healthAndMedicalService")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                :
                                <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }
                            {referralVal?.includes("Skill training") ?
                                <InputText
                                    name="skillTrainingOrganization"
                                    value={props.getValues("skillTrainingOrganization")}
                                    {...props.register("skillTrainingOrganization")}
                                    onChange={(e) => {
                                        props.setValue("skillTrainingOrganization", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }}
                                    placeholder={t("organizationName")}
                                    tooltip={t("skillOrCapacityDevelopmentTraining")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                : <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }
                            {referralVal?.includes("Job placement") ?
                                <InputText
                                    name="jobPlacementOrganization"
                                    value={props.getValues("jobPlacementOrganization")}
                                    {...props.register("jobPlacementOrganization")}
                                    onChange={(e) => {
                                        props.setValue("jobPlacementOrganization", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }}
                                    placeholder={t("organizationName")}
                                    tooltip={t("jobPlacement")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                : <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }
                            {referralVal?.includes("Business support") ?
                                <InputText
                                    name="businessSupportOrganization"
                                    value={props.getValues("businessSupportOrganization")}
                                    {...props.register("businessSupportOrganization")}
                                    onChange={(e) => {
                                        props.setValue("businessSupportOrganization", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }}
                                    placeholder={t("organizationName")}
                                    tooltip={t("businessSupport")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                :
                                <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }
                            {referralVal?.includes("Psychosocial counseling") ?
                                <InputText
                                    name="psychoCounselingOrganization"
                                    value={props.getValues("psychoCounselingOrganization")}
                                    {...props.register("psychoCounselingOrganization")}
                                    onChange={(e) => {
                                        props.setValue("psychoCounselingOrganization", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }}
                                    placeholder={t("organizationName")}
                                    tooltip={t("psychosocialCounseling")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }}
                                />
                                : <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }
                            {referralVal?.includes("Legal services") ?
                                <InputText
                                    name="legalServiceOrganization"
                                    value={props.getValues("legalServiceOrganization")}
                                    {...props.register("legalServiceOrganization")}
                                    onChange={(e) => {
                                        props.setValue("legalServiceOrganization", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }}
                                    placeholder={t("organizationName")}
                                    tooltip={t("legalServices")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }}
                                />
                                : <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }
                            {referralVal?.includes("Formal education") ?
                                <InputText
                                    name="formalEduOrganization"
                                    value={props.getValues("formalEduOrganization")}
                                    {...props.register("formalEduOrganization")}
                                    onChange={(e) => {
                                        props.setValue("formalEduOrganization", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }}
                                    placeholder={t("organizationName")}
                                    tooltip={t("formalEducation")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }}
                                />
                                : <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }
                            {referralVal?.includes("Informal education") ?
                                <InputText
                                    name="informalEduOrganization"
                                    value={props.getValues("informalEduOrganization")}
                                    {...props.register("informalEduOrganization")}
                                    onChange={(e) => {
                                        props.setValue("informalEduOrganization", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }}
                                    placeholder={t("organizationName")}
                                    tooltip={t("informalEducationMotivationalSession")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }}
                                />
                                : <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }

                            {referralVal?.includes("Others") ?
                                <>  <InputText
                                    name="othersReferral"
                                    value={props.getValues("othersReferral")}
                                    {...props.register("othersReferral")}
                                    onChange={(e) => {
                                        props.setValue("othersReferral", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }}
                                    placeholder={t("specifyOtherReferral")}
                                    tooltip={t("specifyOtherReferral")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                    <InputText
                                        name="othersOrganization"
                                        value={props.getValues("othersOrganization")}
                                        {...props.register("othersOrganization")}
                                        onChange={(e) => {
                                            props.setValue("othersOrganization", e.target.value);
                                            setVal(e.target.value);
                                        }}
                                        style={{ marginTop: "5px" }}
                                        placeholder={t("organizationName")}
                                        tooltip={t("others")}
                                        tooltipOptions={{
                                            position: "bottom"
                                        }} />
                                </>
                                : <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }

                        </div>
                    </div>
                </div>
            </div>

            {/* </>
                : <></> }*/}

            <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-col-12 p-md-6 ">
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("caseReintegrationStatus")}
                    </div>
                    <div className="p-field p-col-12 p-md-4 float-left">
                        <RadioButton
                            value={YES_NO.YES}
                            name={YES_NO.YES}
                            onChange={(e) => {
                                props.setValue("caseReintegrationStatus", YES_NO.YES);
                                setType(e.value);
                            }}
                            checked={props.getValues("caseReintegrationStatus") === YES_NO.YES}
                        /> {t("yes")}
                    </div>
                    <div className="p-field p-col-12 p-md-4 float-left">
                        <RadioButton
                            value={YES_NO.NO}
                            name={YES_NO.NO}
                            onChange={(e) => {
                                props.setValue("caseReintegrationStatus", YES_NO.NO);
                                setType(e.value);
                            }}
                            checked={props.getValues("caseReintegrationStatus") === YES_NO.NO} /> {t("no")}
                    </div>
                </div>
                {props.getValues("caseReintegrationStatus") === 'No' ?
                    <div className="p-col-12 p-md-6 float-left ">
                        <div className="p-field p-col-12 p-md-12 main-label">
                            {t("whyNot")}
                        </div>
                        <div className="p-field p-col-12 p-md-12 float-left">
                            <InputText
                                name="noReintegrationReason"
                                {...props.register("noReintegrationReason")}
                                value={props.getValues("noReintegrationReason")}
                                onChange={(e) => {
                                    props.setValue("noReintegrationReason", e.target.value);
                                    setVal(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    : <></>
                }
            </div>
            {props.getValues("caseReintegrationStatus") === 'Yes' ?
                <div className="p-grid p-col-12 p-md-12 ">
                    <div className="p-col-12 p-md-6 ">
                        <div className="p-field p-col-12 p-md-12 main-label">
                            {t("reintegratedTo")}
                        </div>
                        <div className="p-field p-col-12 p-md-12 float-left">
                            <Dropdown
                                name="reintegratedTo"
                                filter
                                placeholder={t("select")}
                                {...props.register("reintegratedTo")}
                                value={props.getValues("reintegratedTo")}
                                options={reintegrationStatusList}
                                onChange={(e) => {
                                    props.setValue("reintegratedTo", e.value);
                                    setType(e.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="p-col-12 p-md-6 ">
                        <div className="p-field p-col-12 p-md-12 main-label">
                            {t("reintegratedDate")}
                        </div>
                        <div className="p-field p-col-12 p-md-12 float-left">
                            <NepaliDatePicker
                                name="reintegratedDate"
                                {...props.register("reintegratedDate")}
                                value={props.getValues("reintegratedDate")}
                                onChange={(value) => {
                                    props.setValue("reintegratedDate", value);
                                }}
                                options={{ calenderLocale: "en", valueLocale: "en" }}
                            />
                        </div>
                    </div>

                </div>
                :
                <></>
            }

            <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-col-12 p-md-6 ">
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("caseDiscussedWithLg")}
                    </div>
                    <div className="p-field p-col-12 p-md-4 float-left">
                        <RadioButton
                            value={YES_NO.YES}
                            name={YES_NO.YES}
                            onChange={(e) => {
                                props.setValue("tipDetail.caseDiscussedWithLg", YES_NO.YES);
                                setTypeOfTipCase(e.value);
                            }}
                            checked={props.getValues("tipDetail.caseDiscussedWithLg") === YES_NO.YES}
                        /> {t("yes")}
                    </div>
                    <div className="p-field p-col-12 p-md-4 float-left">
                        <RadioButton
                            value={YES_NO.NO}
                            name={YES_NO.NO}
                            onChange={(e) => {
                                props.setValue("tipDetail.caseDiscussedWithLg", YES_NO.NO);
                                setTypeOfTipCase(e.value);
                            }}
                            checked={props.getValues("tipDetail.caseDiscussedWithLg") === YES_NO.NO} /> {t("no")}
                    </div>
                </div>
            </div>


            <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-col-12 p-md-6 ">
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("caseReferredToShelterHome")}
                    </div>
                    <div className="p-field p-col-12 p-md-4 float-left">
                        <RadioButton
                            value={YES_NO.YES}
                            name={YES_NO.YES}
                            onChange={(e) => {
                                props.setValue("tipDetail.caseReferredToShelterHome", YES_NO.YES);
                                setTypeOfTipCase(e.value);
                            }}
                            checked={props.getValues("tipDetail.caseReferredToShelterHome") === YES_NO.YES}
                        /> {t("yes")}
                    </div>
                    <div className="p-field p-col-12 p-md-4 float-left">
                        <RadioButton
                            value={YES_NO.NO}
                            name={YES_NO.NO}
                            onChange={(e) => {
                                props.setValue("tipDetail.caseReferredToShelterHome", YES_NO.NO);
                                setTypeOfTipCase(e.value);
                            }}
                            checked={props.getValues("tipDetail.caseReferredToShelterHome") === YES_NO.NO} /> {t("no")}
                    </div>
                </div>
            </div>
            {props.getValues("tipDetail.caseReferredToShelterHome") === 'Yes'
                ? <>
                    <div className="p-grid p-col-12 p-md-12 ">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field p-col-12 p-md-12 float-left main-label">
                                {t("anotherShelterHomeName")}
                            </div>
                            <div className="p-field p-col-12 p-md-12 float-left">
                                <InputText
                                    name="anotherShelterHomeName"
                                    value={props.getValues("tipDetail.anotherShelterHomeName")}
                                    {...props.register("tipDetail.anotherShelterHomeName")}
                                    onChange={(e) => {
                                        props.setValue("tipDetail.anotherShelterHomeName", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>


                    <div className="p-field p-col-12 p-md-12 ">
                        <div className="p-field p-col-12 p-md-12 float-left main-label">
                            {t("address")}
                        </div>
                        <div className="p-field p-col-12 p-md-3 float-left">
                            <Dropdown
                                name="provinceSh"
                                filter
                                placeholder={t("province")}
                                {...props.register("tipDetail.provinceSh")}
                                value={props.getValues("tipDetail.provinceSh")}
                                options={provincesListSh}
                                optionLabel={i18n.language == LANGUAGE.ENGLISH ? "provinceDescEng" : "provinceDescNep"}
                                optionValue="id"
                                onChange={(e) => {
                                    props.setValue("tipDetail.provinceSh", e.value);
                                    getDistrictValueSh(e.value);
                                }}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3 float-left">
                            <Dropdown
                                name="districtSh"
                                filter
                                value={props.getValues("tipDetail.districtSh")}
                                placeholder={t("district")}
                                {...props.register("tipDetail.districtSh")}
                                onChange={(e) => {
                                    props.setValue("tipDetail.districtSh", e.value);
                                    getMunicipalitiesListSh(e.value);
                                }}
                                options={districtsListSh}
                                optionLabel={i18n.language == LANGUAGE.ENGLISH ? "districtDescEng" : "districtDescNep"}
                                optionValue="id"
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3 float-left">
                            <Dropdown
                                name="municipalitySh"
                                filter
                                value={props.getValues("tipDetail.municipalitySh")}
                                placeholder={t("municipality")}
                                {...props.register("tipDetail.municipalitySh")}
                                onChange={(e) => {
                                    props.setValue("tipDetail.municipalitySh", e.value);
                                    getTotalWardSh(e.value);
                                }}
                                options={municipalitiesListSh}
                                optionLabel={
                                    i18n.language == LANGUAGE.ENGLISH ? "municipalityDescEng" : "municipalityDescNep"
                                }
                                optionValue="id"
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-3 float-left">
                            <Dropdown
                                name="wardNoSh"
                                filter
                                value={props.getValues("tipDetail.wardNoSh")}
                                placeholder={t("wardNo")}
                                {...props.register("tipDetail.wardNoSh")}
                                onChange={(e) => {
                                    props.setValue("tipDetail.wardNoSh", e.value);
                                    setAddressValue(e.value);
                                }}
                                options={wardListSh}
                                optionLabel="wardId"
                                optionValue="wardId"
                            />
                        </div>
                    </div>
                </>
                :
                <></>
            }
        </>
    )
}

export default TipDetail2;