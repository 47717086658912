import React, { useState } from 'react';

import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from "primereact/checkbox";

import { Dropdown } from 'primereact/dropdown';
import { LANGUAGE, YES_NO } from '../../utilities/constants/ITMISConstansts';
import { t } from 'i18next';
import { MultiSelect } from 'primereact/multiselect';
import { InputTextarea } from 'primereact/inputtextarea';
import { useForm, Controller } from "react-hook-form";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
import { useEffect } from 'react';
import i18n from '../../il8n/il8n';

const renderList = [{
    label:'label1',
    labelNep:'label nep1',
    value:'label1',
    key:'lable1_key',
    hasInput:true,
},{
    label:'label1',
    labelNep:'label nep1',
    value:'label1',
    key:'lable1_key',
    hasInput:false,
}]

const GbvDetails2 = (props) => {
    // console.log('props.touchedFields:',props);

    const [flag, setFlag] = useState();
    const [typeOfGbvCase, setTypeOfGbvCase] = useState();

    const [directServicesVal, setDirectServicesVal] = useState("No");
    const { control, } = useForm({ mode: "all", });

    let referals = (props.getValues("gbvDetail.referral")||[]).join(',');
    // console.log('referals: ',referals);
    const [val, setVal] = useState();

    useEffect(() => {
        if(flag ) return;
        const refData =props.getValues("gbvDetail.referral") || [];
        
        if(refData.length > 0){
            let newList = referralList.map(a => {
                if (refData.find(val => val === a.value)) {
                    if(!a.checked) {
                        a.checked = !a.checked;
                    }
                }
                return a;
            });
            setFlag(true);
            setValueReferralList(newList);
            setReferralVal(refData);
        }
    }, [referals]);

const directServicesList = [
    { label: t("shelterAccommodation"), value: "Shelter or Accommodation" },
    { label: t("food"), value: "Food" },
    { label: t("clothes"), value: "Clothes" },
    { label: t("psychosocialCounseling"), value: "Psychosocial counseling" },
    { label: t("skillOrCapacityDevelopmentTraining"), value: "Skill or capacity development training" },
    { label: t("formalEducation"), value: "Formal education" },
    { label: t("informalEducationMotivationalSession"), value: "Informal education or motivational session" },
    { label: t("healthAndMedicalService"), value: "Health and medical service" },
    { label: t("jobPlacement"), value: "Job placement" },
    { label: t("businessSupport"), value: "Business support" },
    { label: t("legalServices"), value: "Legal services" },
    { label: t("othersPleaseSpecify"), value: "Others" },
];

const handleDirectServices = (value) => {
    (value.includes("Others") ?
        setDirectServicesVal("Yes") :
        setDirectServicesVal("No")
    )
}

const [referralVal, setReferralVal] = useState([]);

const checkboxReferralListFunction = (val) => {
    console.log("referralList", referralList);
    let list = [];
    let newList = referralList.map(a => {
        if (a.value === val) {
            a.checked = !a.checked;
        }
        return a;
    });
    console.log("newList", newList);
    let newReferralList = newList.map(data => {
        if (data.checked && data.checked === true) {
            console.log("list", list);
            list.push(data.value);
        }
        return list;
    });
    console.log("newReferralList", newReferralList);

    setReferralVal(newReferralList[0]);
    props.setValue("gbvDetail.referral", newReferralList[0]);
    setValueReferralList(newList);
}

const [referralList, setValueReferralList] = useState([
    { labelEng: "Health and medical service", labelNep: "स्वास्थ्य र चिकित्सा", value: "Health service" },
    { labelEng: "Skill or capacity development training", labelNep: "सीप तथा क्षमता विकास तालिम", value: "Skill training" },
    { labelEng: "Job placement", labelNep: "जागिर नियुक्ति", value: "Job placement" },
    { labelEng: "Business support", labelNep: "पेसा व्यवसाय सहायता", value: "Business support" },
    { labelEng: "Psychosocial counseling", labelNep: "मनोसामाजिक परामर्श", value: "Psychosocial counseling" },
    { labelEng: "Legal and paralegal services", labelNep: "कानूनी र पारालीगल सेवाहरू", value: "Legal services" },
    { labelEng: "Formal education", labelNep: "औपचारिक शिक्षा", value: "Formal education" },
    { labelEng: "Informal education/motivational session", labelNep: "अनौपचारिक शिक्षा/प्रेरणा सत्र", value: "Informal education" },
    { labelEng: "Others (Please specify)", labelNep: "अन्य (कृपया उल्लेख गर्नुहोस्)", value: "Others" },
    { labelEng: "Not referred yet", labelNep: "सेवाका लागि रेफर गरिएको छैन", value: "Not referred yet" },
]);

const reintegrationStatusList = [
    { label: t("Family"), value: "Family" },
    { label: t("relatives"), value: "Relatives" },
    { label: t("livingAlone"), value: "Living alone" },
    { label: t("Community"), value: "Community" },
    { label: t("outsideOfCommunity"), value: "Outside of community" }
];


return (
    <>
        <div className="p-field p-col-12 p-md-12 ">
            <h3 style={{ textAlign: "center" }}>{t("servicesRegistered")}</h3>
        </div>

        <div className="p-grid p-col-12 p-md-12 ">
            <div className="p-col-12 p-md-6">
                <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("directServices")}
                </div>
                <div className="p-field p-col-12 p-md-12 float-left">
                    <MultiSelect
                        name="directServices"
                        placeholder={t("select")}
                        {...props.register("gbvDetail.directServices")}
                        value={props.getValues("gbvDetail.directServices")}
                        filter
                        options={directServicesList}
                        onChange={(e) => {
                            props.setValue("gbvDetail.directServices", e.value);
                            handleDirectServices(e.value);
                        }}
                    />
                </div>
            </div>
            {props.getValues("gbvDetail.directServices")?.includes("Others") ?
                <div className="p-col-12 p-md-6">
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("pleaseSpDetails")}
                    </div>
                    <div className="p-field p-col-12 p-md-12 float-left">
                        <InputText
                            name="directServicesOthers"
                            {...props.register("gbvDetail.directServicesOthers")}
                            value={props.getValues("gbvDetail.directServicesOthers")}
                            onChange={(e) => {
                                props.setValue("gbvDetail.directServicesOthers", e.target.value);
                                setVal(e.target.value);
                            }}
                        />
                    </div>
                </div>
                :
                <></>
            }
        </div>

        <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-col-12 p-md-6" style={{ width: "100%" }}>
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("referral")}
                    </div>
                    <div className="p-field p-col-12 p-md-12 float-left" style={{ marginTop: "10px" }}>
                        <div className="p-col-12 p-md-6 float-left">
                            {referralList.map((referral) => {
                                return (
                                    <div key={referral.key} className="p-col-12 p-md-12 flex align-items-center">
                                        <Checkbox
                                            name="referral"
                                            value={props.getValues("gbvDetail.referral")}
                                            {...props.register("gbvDetail.referral")}
                                            // checked={gbvDetail.value === referral}
                                            checked={referral.checked}
                                            onChange={(e) => {
                                                checkboxReferralListFunction(referral.value)
                                                console.log(referral);

                                            }}
                                        />
                                           <label>{i18n.language == LANGUAGE.ENGLISH ? referral.labelEng : referral.labelNep}</label>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="p-col-12 p-md-6 float-right">
                            {referralVal?.includes("Health service") ?
                                <InputText
                                    name="healthOrganization"
                                    value={props.getValues("gbvDetail.healthOrganization")}
                                    {...props.register("gbvDetail.healthOrganization")}
                                    onChange={(e) => {
                                        props.setValue("gbvDetail.healthOrganization", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }}
                                    placeholder={t("organizationName")}
                                    tooltip={t("healthAndMedicalService")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }}  />
                                :
                                <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }
                            {referralVal?.includes("Skill training") ?
                                <InputText
                                    name="skillTrainingOrganization"
                                    value={props.getValues("gbvDetail.skillTrainingOrganization")}
                                    {...props.register("gbvDetail.skillTrainingOrganization")}
                                    onChange={(e) => {
                                        props.setValue("gbvDetail.skillTrainingOrganization", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }}
                                    placeholder={t("organizationName")}
                                    tooltip={t("skillOrCapacityDevelopmentTraining")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }}  />
                                : <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }
                            {referralVal?.includes("Job placement") ?
                                <InputText
                                    name="jobPlacementOrganization"
                                    value={props.getValues("gbvDetail.jobPlacementOrganization")}
                                    {...props.register("gbvDetail.jobPlacementOrganization")}
                                    onChange={(e) => {
                                        props.setValue("gbvDetail.jobPlacementOrganization", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }}
                                    placeholder={t("organizationName")}
                                    tooltip={t("jobPlacement")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }}  />
                                : <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }
                            {referralVal?.includes("Business support") ?
                                <InputText
                                    name="businessSupportOrganization"
                                    value={props.getValues("gbvDetail.businessSupportOrganization")}
                                    {...props.register("gbvDetail.businessSupportOrganization")}
                                    onChange={(e) => {
                                        props.setValue("gbvDetail.businessSupportOrganization", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }} 
                                    placeholder={t("organizationName")}
                                    tooltip={t("businessSupport")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                :
                                <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }
                            {referralVal?.includes("Psychosocial counseling") ?
                                <InputText
                                    name="psychoCounselingOrganization"
                                    value={props.getValues("gbvDetail.psychoCounselingOrganization")}
                                    {...props.register("gbvDetail.psychoCounselingOrganization")}
                                    onChange={(e) => {
                                        props.setValue("gbvDetail.psychoCounselingOrganization", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }}
                                    placeholder={t("organizationName")}
                                    tooltip={t("psychosocialCounseling")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} 
                                />
                                : <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }
                            {referralVal?.includes("Legal services") ?
                                <InputText
                                    name="legalServiceOrganization"
                                    value={props.getValues("gbvDetail.legalServiceOrganization")}
                                    {...props.register("gbvDetail.legalServiceOrganization")}
                                    onChange={(e) => {
                                        props.setValue("gbvDetail.legalServiceOrganization", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }}
                                    placeholder={t("organizationName")}
                                    tooltip={t("legalServices")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} 
                                />
                                : <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }
                            {referralVal?.includes("Formal education") ?
                                <InputText
                                    name="formalEduOrganization"
                                    value={props.getValues("gbvDetail.formalEduOrganization")}
                                    {...props.register("gbvDetail.formalEduOrganization")}
                                    onChange={(e) => {
                                        props.setValue("gbvDetail.formalEduOrganization", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }} 
                                    placeholder={t("organizationName")}
                                    tooltip={t("formalEducation")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} 
                                    />
                                : <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }
                            {referralVal?.includes("Informal education") ?
                                <InputText
                                    name="informalEduOrganization"
                                    value={props.getValues("gbvDetail.informalEduOrganization")}
                                    {...props.register("gbvDetail.informalEduOrganization")}
                                    onChange={(e) => {
                                        props.setValue("gbvDetail.informalEduOrganization", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }}
                                    placeholder={t("organizationName")}
                                    tooltip={t("informalEducationMotivationalSession")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} 
                                     />
                                : <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }

                            {referralVal?.includes("Others") ?
                                <>  <InputText
                                    name="othersReferral"
                                    value={props.getValues("gbvDetail.othersReferral")}
                                    {...props.register("gbvDetail.othersReferral")}
                                    onChange={(e) => {
                                        props.setValue("gbvDetail.othersReferral", e.target.value);
                                        setVal(e.target.value);
                                    }}
                                    style={{ marginTop: "5px" }} 
                                    placeholder={t("specifyOtherReferral")}
                                    tooltip={t("specifyOtherReferral")}
                                    tooltipOptions={{
                                        position: "bottom"
                                    }} />
                                    <InputText
                                        name="othersOrganization"
                                        value={props.getValues("gbvDetail.othersOrganization")}
                                        {...props.register("gbvDetail.othersOrganization")}
                                        onChange={(e) => {
                                            props.setValue("gbvDetail.othersOrganization", e.target.value);
                                            setVal(e.target.value);
                                        }}
                                        style={{ marginTop: "5px" }} 
                                        placeholder={t("organizationName")}
                                        tooltip={t("others")}
                                        tooltipOptions={{
                                            position: "bottom"
                                        }} />
                                </>
                                : <InputText style={{ marginTop: "5px", border: "none" }} disabled />
                            }

                        </div>
                    </div>
                </div>
            </div>

        {/* <div className="p-field p-col-12 p-md-12 float-left">
                        <Dropdown
                            name="referral"
                            placeholder={t("select")}
                            {...props.register("gbvDetail.referral")}
                            value={props.getValues("gbvDetail.referral")}
                            options={referralList}
                            onChange={(e) => {
                                props.setValue("gbvDetail.referral", e.value);
                                setTypeOfGbvCase(e.value)
                            }}
                        />
                    </div>
                </div>
                <div className="p-col-12 p-md-6">
                    <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("organization")}
                    </div>
                    <div className="p-field p-col-12 p-md-12 float-left">
                        <InputText
                            name="referralOrganization"
                            value={props.getValues("gbvDetail.referralOrganization")}
                            {...props.register("gbvDetail.referralOrganization")}
                            onChange={(e) => {
                                props.setValue("gbvDetail.referralOrganization", e.target.value);
                            }}
                        />
                    </div>
                </div>
            </div> */}

        <div className="p-grid p-col-12 p-md-12 ">
            <div className="p-col-12 p-md-6 ">
                <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("caseReintegrationStatus")}
                </div>
                <div className="p-field p-col-12 p-md-4 float-left">
                    <RadioButton
                        value={YES_NO.YES}
                        name={YES_NO.YES}
                        onChange={(e) => {
                            props.setValue("gbvDetail.caseReintegrationStatus", YES_NO.YES);
                            setTypeOfGbvCase(e.value);
                        }}
                        checked={props.getValues("gbvDetail.caseReintegrationStatus") === YES_NO.YES}
                    /> {t("yes")}
                </div>
                <div className="p-field p-col-12 p-md-4 float-left">
                    <RadioButton
                        value={YES_NO.NO}
                        name={YES_NO.NO}
                        onChange={(e) => {
                            props.setValue("gbvDetail.caseReintegrationStatus", YES_NO.NO);
                            setTypeOfGbvCase(e.value);
                        }}
                        checked={props.getValues("gbvDetail.caseReintegrationStatus") === YES_NO.NO} /> {t("no")}
                </div>
            </div>
            {props.getValues("gbvDetail.caseReintegrationStatus") === 'No' ?
                <div className="p-col-12 p-md-6 float-left ">
                    <div className="p-field p-col-12 p-md-12 main-label">
                        {t("whyNot")}
                    </div>
                    <div className="p-field p-col-12 p-md-12 float-left">
                        <InputText
                            name="noReintegrationReason"
                            {...props.register("gbvDetail.noReintegrationReason")}
                            value={props.getValues("gbvDetail.noReintegrationReason")}
                            onChange={(e) => {
                                props.setValue("gbvDetail.noReintegrationReason", e.target.value);
                                setVal(e.target.value);
                            }}
                        />
                    </div>
                </div>
                : <></>
            }
        </div>
        {props.getValues("gbvDetail.caseReintegrationStatus") === 'Yes' ?
            <div className="p-grid p-col-12 p-md-12 ">
                <div className="p-col-12 p-md-6 ">
                    <div className="p-field p-col-12 p-md-12 main-label">
                        {t("reintegratedTo")}
                    </div>
                    <div className="p-field p-col-12 p-md-12 float-left">
                        <Dropdown
                            name="reintegratedTo"
                            filter
                            placeholder={t("select")}
                            {...props.register("gbvDetail.reintegratedTo")}
                            value={props.getValues("gbvDetail.reintegratedTo")}
                            options={reintegrationStatusList}
                            onChange={(e) => {
                                props.setValue("gbvDetail.reintegratedTo", e.value);
                                setTypeOfGbvCase(e.value)
                            }}
                        />
                    </div>
                </div>
                <div className="p-col-12 p-md-6">
                    <div className="p-field p-col-12 p-md-12 main-label">
                        {t("reintegratedDate")}
                    </div>
                    <div className="p-field p-col-12 p-md-12 float-left">
                        <NepaliDatePicker
                            name="reintegratedDate"
                            {...props.register("gbvDetail.reintegratedDate")}
                            value={props.getValues("gbvDetail.reintegratedDate")}
                            onChange={(value) => {
                                props.setValue("gbvDetail.reintegratedDate", value);
                            }}
                            options={{ calenderLocale: "en", valueLocale: "en" }}
                        />
                    </div>
                </div>

            </div>
            :
            <></>
        }

        <div className="p-grid p-col-12 p-md-12 ">
            <div className="p-col-12 p-md-12 ">
                <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("caseDiscussedWithLg")}
                </div>
                <div className="p-field p-col-12 p-md-4 float-left">
                    <RadioButton
                        value={YES_NO.YES}
                        name={YES_NO.YES}
                        onChange={(e) => {
                            props.setValue("gbvDetail.caseDiscussedWithLg", YES_NO.YES);
                            setTypeOfGbvCase(e.value);
                        }}
                        checked={props.getValues("gbvDetail.caseDiscussedWithLg") === YES_NO.YES}
                    /> {t("yes")}
                </div>
                <div className="p-field p-col-12 p-md-4 float-left">
                    <RadioButton
                        value={YES_NO.NO}
                        name={YES_NO.NO}
                        onChange={(e) => {
                            props.setValue("gbvDetail.caseDiscussedWithLg", YES_NO.NO);
                            setTypeOfGbvCase(e.value);
                        }}
                        checked={props.getValues("gbvDetail.caseDiscussedWithLg") === YES_NO.NO} /> {t("no")}
                </div>
            </div>
        </div>
    </>
)
}
export default GbvDetails2;